

export const formTypes = {
    inputChange: '[NormalForm] Input changed',
    cleanForm: '[NormalForm] Clean Values',
    initForm: '[NormalForm] Init Form structure',
    addRepeaterRegister: '[NormalForm] Add register for repeater',
    removeRepeaterRegister: '[NormalForm] Remove register for repeater',
    editRepeaterRegister: '[NormalForm] Edit register for repeater',
    fillFormData: '[NormalForm] Fill form data',
    cleanSectionForm: '[NormalForm] Clean Section'
}