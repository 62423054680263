

export const canvasTypes = {
    LoadElement: '[Canvas] Load element',
    DropElement: '[Canvas] Element droped',
    RemoveElement: '[Canvas] Remove element',
    CloneElement: '[Canvas] Element cloned',
    SaveCanvas: '[Canvas] Save Canvas',
    CleanCanvas: '[Canvas] Clean canvas',
    DeleteCanvasElement: '[Canvas] delete one element',
    setAllCanvas: '[Canvas] set all canvas'
}